@tailwind base;
@tailwind components;
@tailwind utilities;
.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.privacy h1 {
    font-size: 28px;
    margin-bottom: 20px;
    color: black;
}

.privacy a {
    color: #4200ff;
}

.index-header-font-size {
    font-size: calc(-3px + 4.16667vw);
}

.index-banner-bg {
    height: calc(53vh + 8vw);
    background: linear-gradient(115deg, #fff 50%, transparent 45%) center center / 100% 100%, url("./assets/index-banner.jpeg") right center/ auto 100%;
    background-size: cover;
}

.index-banner-bg-second {
    background: url("./assets/index-banner-pink.jpeg");
    background-size: cover;
    clip-path: polygon(0 0, 80% 0, 10% 100%, 0 100%);
}

.index-banner-second-font-size {
    font-size: calc(9px + 2.08333vw);
}